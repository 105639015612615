html {
  margin: 0;
  padding: 0;
  touch-action: pan-x pan-y;
}

body {
  overflow: overlay;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar:hover {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #02ca8e;
}

@keyframes heartBeat {
  0% {
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
  28% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
}

@keyframes alarmAnimation {
  0% {
    top: 0;
  }
  25% {
    top: -72px;
  }
  75% {
    top: -144px;
  }
  100% {
    top: 0;
  }
}

.animate__heartBeat {
  animation: heartBeat 1s forwards;
}
